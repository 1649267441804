<template>
  <el-container>
    <el-aside width="250px" class="x-aside">
      <x-menu />
    </el-aside>

    <el-container>
      <el-header height="60px" class="header">
        <el-row type="flex" justify="space-between">
          <el-col :span="12">
            <h4 class="welcome">欢迎来到稀元数藏管理后台</h4>
          </el-col>
          <el-col :span="12">
            <div class="avatar-box">
              <el-dropdown placement="bottom" @command="commandHandle">
                <div class="el-dropdown-link">
                  <img src="@img/icon_avatar.png" class="avatar" />
                  <span
                    style="
                      line-height: 40px;
                      display: inline-block;
                      vertical-align: text-bottom;
                    "
                  >
                    系统管理员
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item class="font-primary" command="dashboard"
                    >数据看板</el-dropdown-item
                  >
                  <el-dropdown-item divided class="font-danger" command="exit"
                    >退出系统</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-col>
        </el-row>
      </el-header>

      <el-main style="padding: 0; background-color: #f8f9fa">
        <transition name="fade-transform" mode="out-in">
          <router-view :key="key" />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import XMenu from "@/components/menu/XMenu";

export default {
  name: "Layout",
  components: {
    XMenu,
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  methods: {
    commandHandle(cmd) {
      if (cmd === "exit") {
        this.$store.dispatch("LogoutHandle").then((r) => {
          this.$store.dispatch("clearMenu");
          window.localStorage.removeItem("currentMenu");
          this.$router.push("/login");
        });
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style lang="less" scoped>
// .nullbox{
//   height: 100vh;
// }
.header {
  line-height: 60px;
  background-color: #fff;
  border-bottom: 1px solid #eee;

  .welcome {
    color: #999999;
    font-weight: 600;
    font-size: 15px;
  }
}

.avatar-box {
  float: right;
  margin: 8px 0 0 25px;
  cursor: pointer;
}

.avatar {
  margin-right: 12px;
  width: 36px;
  height: 36px;
}
/deep/ .x-menu-bar {
  position: fixed;
  width: 250px;
}
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.5s;
}
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
