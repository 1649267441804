import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import RouterBrand from "./modules/brand"; // 品牌管理模块
import RouterCollection from "./modules/collection"; // 臧品集管理模块
import RouterNft from "./modules/nft";
import RouterMarket from "./modules/market";
import RouterAudit from "./modules/audit";
import RouterOrder from "./modules/order";
import RouterUser from "./modules/user";
import RouterApp from "./modules/app";
import RouterNotice from "./modules/notice";
import RouterCompose from "./modules/compose";
import RouterAirDrop from './modules/airdrop';
import RouterBalance from './modules/balance';




Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/Layout"),
      meta: {
        requireLogin: true,
        title: "稀元平台后台管理系统",
      },
      children: [
        {
          path: "dashboard",
          component: () => import("@/views/dashboard/Index"),
          meta: {
            requireLogin: true,
            title: "数据看台",
          },
        },
        ...RouterBrand,
        ...RouterCollection,
        ...RouterNft,
        ...RouterMarket,
        ...RouterAudit,
        ...RouterOrder,
        ...RouterUser,
        ...RouterApp,
        ...RouterNotice,
        ...RouterCompose,
        ...RouterAirDrop,
        ...RouterBalance
      ],
    },
    {
      path: "/login",
      component: () => import("@/views/common/Login"),
      meta: {
        requireLogin: false,
        title: "登录",
      },
    },
    {
      path: "/500",
      name: "Page500",
      component: () => import("@/views/common/Page500"),
      meta: {
        requireLogin: false,
        title: "500-服务器故障",
      },
    },
    {
      path: "*",
      name: "Page404",
      component: () => import("@/views/common/Page404"),
      meta: {
        requireLogin: false,
        title: "404-未发现资源",
      },
    },
  ],
});

/**
 * 1.设置页面标题title
 * 2.避免刷新掉线
 * 3.页面鉴权
 */
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "稀元数藏后台管理系统";
  const baseInfo = JSON.parse(window.localStorage.getItem("baseInfo"));
  // 防止vuex刷新刷掉用户信息
  if (baseInfo && baseInfo.isLogin) {
    if (!store.state.user.isLogin) {
      store.dispatch("SetLogin", baseInfo);
    }
  }
  const routerPath = to.fullPath
  const path = window.localStorage.getItem("currentMenu");
  const currentMenu = store.state.currentMenu;
  // url修改路由，菜单栏回显
  if (path && routerPath != path && routerPath != '/login') {
    store.dispatch("setMenu", routerPath);
  }
  if (!currentMenu) {
    // 防止vuex刷新后路由信息丢失，导致菜单不显示active
    if (path && routerPath != path) {
      store.dispatch("setMenu", routerPath);
    } else if (path && routerPath == path) {
      store.dispatch("setMenu", path);
    }
  }
  if (to.matched.some((res) => res.meta.requireLogin)) {
    baseInfo && baseInfo.isLogin ? next() : next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
